import { defineStore } from 'pinia';
import type { Activity, ActivityPostBody, activityPutBody } from '~/types/activity';

export const useActivitiesStore = defineStore('activitiesStore', () => {
    const {
        data: activities,
        refresh: refreshActivities,
        execute,
        status
    } = useFetch<Activity[]>('/api/activites', { default: () => [], immediate: true });

    const selectActivities = computed(() => {
        return activities.value.map(({ id, name }) => ({ value: id, label: name?.fr }));
    });

    const awaitActivities = async () => {
        if (status.value === 'success') return;
        else if (status.value === 'idle') await execute();
        else
            await new Promise<void>((resolve, reject) => {
                const stop = watchEffect(
                    () => {
                        if (status.value === 'success') {
                            stop();
                            resolve();
                        }
                        if (status.value === 'error') {
                            stop();
                            reject(new Error('Erreur lors du chargement du produit'));
                        }
                    },
                    { flush: 'sync' }
                );
            });
    };

    const createActivity = async (body?: ActivityPostBody) => {
        await $fetch('/api/activity/', {
            body,
            method: 'POST'
        });
        await refreshActivities();
    };

    const modifyActivity = async (productId: number, modifOptions: activityPutBody) => {
        await $fetch(`/api/activity/${productId}`, {
            body: modifOptions,
            method: 'PUT'
        });
        await refreshActivities();
    };

    return {
        activities,
        refreshActivities,
        selectActivities,
        awaitActivities,
        createActivity,
        modifyActivity
    };
});
